/* global mapbox_token */

import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';

mapboxgl.accessToken = mapbox_token;

if(document.querySelector('#geocoder-city') !== undefined) {
  const geocoder = new MapboxGeocoder({
    accessToken: mapboxgl.accessToken, // Set the access token
    placeholder: 'Choisissez une ville',
    mapboxgl: mapboxgl, // Set the mapbox-gl instance
    marker: false, // Do not use the default marker style
    collapsed: false,
    countries: 'fr',
  });

  geocoder.addTo('#geocoder-city');

  const latitude = document.querySelector('#geocoder-latitude');
  const longitude = document.querySelector('#geocoder-longitude');
  const mapboxDiv = document.querySelector('.mapboxgl-ctrl-geocoder--input');

  // Add geocoder result to container.
  geocoder.on('result', (e) => {
    localStorage.setItem('cityInput', e.result['place_name']);
    longitude.value = e.result['geometry']['coordinates'][0];
    latitude.value = e.result['geometry']['coordinates'][1];
  });

  let cityInput = localStorage.getItem('cityInput');

  // Clear results container when search is cleared.
  geocoder.on('clear', () => {
    longitude.value = '';
    latitude.value = '';
  });

  // Change the style of the geocoder input
  mapboxDiv.classList.add("form-control");
  mapboxDiv.classList.remove("mapboxgl-ctrl-geocoder--input");
  mapboxDiv.setAttribute('name', 'input-city');
  mapboxDiv.setAttribute('autocomplete', 'off');
  mapboxDiv.setAttribute('id', 'geocoder-input');

  if (cityInput != null) {
    // geocoder.query(cityInput);
  }
}

const resetButton = document.querySelector('#reset-filter-link');
const formSelect = document.querySelector('#project');
const formGeocoderInput = document.querySelector('#geocoder-input');
var formRadioButton = document.getElementsByName("rangeRadios");

if (resetButton) {
  resetButton.addEventListener('click', () => {
    formSelect.value = '';
    formGeocoderInput.value = '';
    for(var i=0;i<formRadioButton.length;i++) {
      formRadioButton[i].checked = false;
    }
  });
}

/* global mapbox_token */

import mapboxgl from 'mapbox-gl';
import MapboxLanguage from '@mapbox/mapbox-gl-language';

import './geocoder';

mapboxgl.accessToken = mapbox_token;

const marker_options = {
  offset: {
    x: 0,
    y: 0,
  },
};

const current_markers = [];

if (document.getElementById('map')) {
  const map = new mapboxgl.Map({
    container: 'map',
    style: 'mapbox://styles/mapbox/streets-v11',
    center: [-0.5678536320494914, 44.85490826489278],
    zoom: 2,
  });

  // map.scrollZoom.disable();
  map.addControl(new mapboxgl.NavigationControl());
  map.addControl(new MapboxLanguage({
    defaultLanguage: 'fr',
  }));

  map.on('load', function () {
    document.getElementById('programs-map-loader').remove();

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    let params = new URLSearchParams();
    params.append('action', 'get_geojson');
    if (urlParams.get('project-type')) {
      params.append('project-type', urlParams.get('project-type'));
    }

    if (urlParams.get('geocoder-latitude') && urlParams.get('geocoder-longitude') && urlParams.get('rangeRadios')) {
      params.append('geocoder-latitude', urlParams.get('geocoder-latitude'));
      params.append('geocoder-longitude', urlParams.get('geocoder-longitude'));
      params.append('rangeRadios', urlParams.get('rangeRadios'));
    }

    if (urlParams.get('input-city')) {
      params.append('input-city', urlParams.get('input-city'));
    }

    const url = `/wp-admin/admin-ajax.php?${params.toString()}`;

    $.get(url, function (geojson) {
      setMarkers(geojson, map);
    });
  });
}

function getBoundingBox(data) {
  let bounds = {}, coords, latitude, longitude;

  for (let i = 0; i < data.features.length; i++) {
    coords = data.features[i].geometry.coordinates;
    longitude = coords[0];
    latitude = coords[1];
    bounds.x_min = bounds.x_min < longitude ? bounds.x_min : longitude;
    bounds.x_max = bounds.x_max > longitude ? bounds.x_max : longitude;
    bounds.y_min = bounds.y_min < latitude ? bounds.y_min : latitude;
    bounds.y_max = bounds.y_max > latitude ? bounds.y_max : latitude;
  }

  return bounds;
}

function setMarkers(geojson, map) {
  const coordinates = [];

  for (var i = current_markers.length - 1; i >= 0; i--) {
    current_markers[i].remove();
  }

  geojson.features.forEach(function (location) {
    const el = document.createElement('div');
    el.className = 'marker';

    const marker = new mapboxgl.Marker(el, marker_options)
      .setLngLat(location.geometry.coordinates);

    if (typeof location.properties !== 'undefined') {
      marker.setPopup(
        new mapboxgl.Popup({ offset: 20 }).setHTML(location.properties.html)
      );
    }

    marker.addTo(map);

    current_markers.push(marker);
    coordinates.push(location.geometry.coordinates);
  });

  if (coordinates.length > 1) {
    const bounding_box = getBoundingBox(geojson);

    map.fitBounds([
      [bounding_box.x_min, bounding_box.y_min],
      [bounding_box.x_max, bounding_box.y_max],
    ], {
      padding: 100,
      maxZoom: 12,
    });
  } else if (coordinates.length === 1) {
    map.flyTo({
      center: coordinates[0],
      zoom: 12,
    });
  }
}
